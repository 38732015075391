<template>
  <dialog
    :id="id"
    ref="dialogRootEl"
    class="fixed left-0 top-0 z-40 m-0 size-full min-h-full min-w-full overflow-x-hidden bg-transparent backdrop:bg-transparent"
    @keypress.prevent.esc="close"
  >
    <div
      ref="dialogEl"
      class="flex size-full items-center justify-center transition-colors"
      :class="dialogOpen ? 'bg-blue-dark/60' : 'bg-transparent'"
    >
      <transition name="fade-bottom">
        <div
          v-show="contentOpen"
          ref="contentEl"
          class="shadow-lg container mx-2 block max-h-[calc(100dvh-.5rem)] justify-center gap-8 self-center overflow-y-auto overflow-x-hidden rounded border-2 border-text-accent bg-back-accent p-4 shadow-text tablet:mx-4 tablet:max-h-[calc(100dvh-8rem)] tablet:p-6 dark:shadow-green-dark/[.1]"
          v-bind="$attrs"
        >
          <header
            v-if="headline || $slots.headline || !hideClose"
            class="sticky top-0 z-10 flex flex-row flex-nowrap gap-8 rounded-t border-b-2 transition-colors"
            :class="[
              headline || $slots.headline ? 'bg-back-accent p-4' : '-mb-[3.75rem]',
              headline || ($slots.headline && scrolled) ? 'border-back-accent' : 'border-transparent',
            ]"
          >
            <slot name="headline">
              <h2 v-if="headline" class="text-center text-3xl font-semibold empty:hidden">
                {{ headline }}
              </h2>
            </slot>
            <form v-if="!hideClose" class="ml-auto text-3xl">
              <button
                formmethod="dialog"
                autofocus
                class="grid size-12 place-content-center rounded bg-back p-2 outline outline-2 transition-colors duration-75 hover:outline-text focus:outline-text active:outline-text-accent"
                :class="!headline && !$slots.headline && scrolled ? 'outline-text-accent' : 'outline-transparent'"
                @click.prevent="close"
              >
                ✗
              </button>
            </form>
          </header>
          <main :key="stateIndex">
            <slot :open="open" :close="close" />
          </main>
        </div>
      </transition>
    </div>
  </dialog>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ modelValue?: boolean; headline?: string; hideClose?: boolean; id?: string | undefined; }>(), {
  modelValue: false,
  headline: "",
  hideClose: false,
  id: undefined,
})
const emit = defineEmits<{ "update:modelValue": [value: boolean]; open: []; close: []; }>()

defineExpose({ open, close, id: props.id })
defineSlots<{ default(props: { open: () => void; close: () => void; }): any; headline(): any; }>()
defineOptions({ inheritAttrs: false })

const dialogRootEl = ref<HTMLDialogElement>()
const dialogEl = ref<HTMLDivElement>()
const contentEl = ref<HTMLDivElement>()

const dialogOpen = ref(false)
const contentOpen = ref(false)

const stateIndex = ref(0)

onMounted(() => {
  props.modelValue && open()
  onContentScroll()
  contentEl.value?.addEventListener("scroll", onContentScroll, { passive: true })
})

onBeforeUnmount(() => {
  contentEl.value?.removeEventListener("scroll", onContentScroll)
})

const scrolled = ref(false)

function onContentScroll() {
  scrolled.value = (contentEl.value?.scrollTop ?? 0) > 0
}

function open() {
  function openContent() {
    contentOpen.value = true
  }

  emit("update:modelValue", true)
  emit("open")
  dialogRootEl.value?.showModal()
  dialogOpen.value = true
  setTimeout(openContent, 100)
}

function close() {
  function contentClose() {
    dialogOpen.value = false
  }

  function dialogClose() {
    dialogRootEl.value?.close()
    emit("update:modelValue", false)
    emit("close")
    setTimeout(resetState, 200)
  }
  setTimeout(contentClose, 100)
  setTimeout(dialogClose, 200)

  contentOpen.value = false
}

function resetState() {
  stateIndex.value++
}

watch(
  () => props.modelValue,
  () => (props.modelValue ? open() : close()),
)
</script>

<style scoped>
:slotted(.component-grid) {
  position: unset !important;
}
</style>
